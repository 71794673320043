import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

function AddPromotion() {
	const domainBackend = "https://vxp1346.uta.cloud/backend/kalyan/";
	const navigation = useNavigate();
	let user_data = JSON.parse(localStorage.getItem('user_data'));

	const [prodName, setProdName] = useState('');
    const [promotionType, setPromotionType] = useState('');
    const [prodImage, setProdImage] = useState(null);
    const [prodMessage, setProdMessage] = useState('');

	const addPromotion = async () => {
		let payload = {
            prodName,
            promotionType,
            prodImage: '',
            prodMessage,
            action: 'create'
        }

		try {
			if (!payload.prodName || !payload.promotionType || !payload.prodMessage) {
				alert('Missing Mandatory Fields');
			} else {
                const formData = new FormData();
                formData.append("file", prodImage);

				const resp = await axios({
					method: "post",
					url: domainBackend + 'wdm_upload_asserts.php',
					data: formData,
					headers: { "Content-Type": "multipart/form-data" }
				});

				if (resp.data && resp.data.success) {
                    payload.prodImage = resp.data.url;

                    const response = await axios({
                        method: "post",
                        url: domainBackend + 'wdm_promotions.php',
                        data: payload,
                        headers: { "Content-Type": "application/json" }
                    });

                    if (response.data && response.data.success) {
                        alert('Promotion Added Successfully');
                        setProdName('');
                        setPromotionType('');
                        setProdMessage('');
                    } else {
                        alert(response.data.message);
                    }
				} else {
					alert(resp.data.message);
				}
			}
		} catch(e) {
			console.log(e);
		}
	}

	return (
		<div className="amado_product_area profile_section">
				<div className="grd-container-fluid">
					<div className="grd-row">
						<div className="grd-12 grd-lg-12 grd-md-12">
							<div className="page_title prod_sub_title">
								<h3>Add Promotions</h3>
							</div>
						</div>
					</div>

					<div className="grd-row">
						<div className="grd-lg-2">
							<div className="menu-tabs">
							<ul>
					<li
					style={{
						marginBottom: "15px",
						borderRadius: "5px",
						padding: "10px 20px",
						listStyle: "none",
						background: "black",
					}}
					>
					<Link style={{ color: "white" }} to="/myaddproduct">
						All Products
					</Link>
					</li>
					<li
					style={{
						marginBottom: "15px",
						borderRadius: "5px",
						padding: "10px 20px",
						listStyle: "none",
						background: "black",
					}}
					>
					<Link style={{ color: "white" }} to="/addpromotion">
						Add Promotion
					</Link>
					</li>
					<li
					style={{
						marginBottom: "15px",
						borderRadius: "5px",
						padding: "10px 20px",
						listStyle: "none",
						background: "black",
					}}
					>
					<Link style={{ color: "white" }} to="/businesshome">
						Chat History
					</Link>
					</li>
				</ul>
							</div>
						</div>
						<div className="grd-lg-8">
						<div className="add_prod_section sds_add_p">
							<div className="add_club_main_section">
							
								<div className="inner_prod_block">
									<div className="image-block"></div>
									<div className="prod_sse">
										<ul>
											<li>
												<h3>Product Name</h3>
												<input type="text" placeholder="product name" value={prodName} onChange={event => setProdName(event.target.value)}/>
											</li>
											<li>
												<h3>Promotion Type</h3>
												<input type="text" placeholder="Promotion type" value={promotionType} onChange={event => setPromotionType(event.target.value)}/>
											</li>
											<li>
												<h3>Apporch Media</h3>
												<input type="file"  onChange={event => setProdImage(event.target.files[0])}/>
											</li>
											<li>
												<h3>Product Message</h3>
												<textarea placeholder="Product Description" value={prodMessage} onChange={event => setProdMessage(event.target.value)}></textarea>
											</li>
											<li>
												<div className="btn_adnn">
													<button className="new_prod" onClick={addPromotion}>Add</button>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						</div>
					</div>
				</div>
			</div>
	)
}

export default AddPromotion