import React from 'react'

function HomeStudent() {
  return (
    <div class="amado_product_area profile_section">
      <div class="grd-container-fluid">
        <div class="grd-row">
          <div class="grd-12 grd-lg-12 grd-md-12">
            <div class="page_title prod_sub_title">
              <h3>Student Home</h3>
            </div>
          </div>
        </div>

        
      </div>
    </div>
  )
}

export default HomeStudent