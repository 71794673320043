import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

function QueryView() {
	const domainBackend = "https://vxp1346.uta.cloud/backend/kalyan/";
	const navigation = useNavigate();
	let user_data = JSON.parse(localStorage.getItem('user_data'));
	const [enquiry, setEnquiry] = useState([]);

	useEffect(() => {
        let access_token = localStorage.getItem('token');
        if (!access_token)
            navigation("/auth");
		readQuery();
    }, []);

	async function readQuery() {
		let payload = {};

		try {
			const resp = await axios({
				method: "post",
				url: domainBackend + 'wdm_get_enquiry.php',
				data: payload,
				headers: { "Content-Type": "application/json" }
			});

			if (resp.data && resp.data.success) {
				let records = JSON.parse(resp.data.data);
				setEnquiry(records.map((recd, index) => (
					<li key={index}>
						<div className="querie_block">
							<div className="inner_bbs">
							<h3>{recd.name}</h3>
							<p>{recd.message}</p>
							</div>
						</div>
					</li>
				)))
			}
		} catch(e) {
			console.log(e);
		}
	}

	return (
		<div className="amado_product_area profile_section">
		<div className="grd-container-fluid">
			<div className="grd-row">
			<div className="grd-12 grd-lg-12 grd-md-12">
				<div className="page_title prod_sub_title">
				<h3>Super Admin</h3>
				</div>
			</div>
			</div>

			<div className="grd-row">
			<div className="grd-lg-2">
				<div className="menu-tabs">
				<ul>
					<div className="menu-tabs">
					<ul>
						<li
						style={{
							marginBottom: "15px",
							borderRadius: "5px",
							padding: "10px 20px",
							listStyle: "none",
							background: "black",
						}}
						>
						<Link style={{ color: "white" }} to="/school_manage">
							Manage Schools
						</Link>
						</li>
						<li
						style={{
							marginBottom: "15px",
							borderRadius: "5px",
							padding: "10px 20px",
							listStyle: "none",
							background: "black",
						}}
						>
						<Link style={{ color: "white" }} to="/user_manage">
							Manage Users
						</Link>
						</li>
						<li
						style={{
							marginBottom: "15px",
							borderRadius: "5px",
							padding: "10px 20px",
							listStyle: "none",
							background: "black",
						}}
						>
						<Link style={{ color: "white" }} to="/query_view">
							View Queries
						</Link>
						</li>
					</ul>
					</div>
				</ul>
				</div>
			</div>
			<div className="grd-lg-9 modarate_pc_section">
				<div className="grd-row">
				<div className="grd-lg-12">
					<div className="reports_title mp_title">
					<h3>View Queries</h3>
					</div>
					<div className="queries_section">
					<ul>
						{enquiry}
						{/* <li>
						<div className="querie_block">
							<div className="inner_bbs">
							<h3>Student Name</h3>
							<p>Querys gets posted here. </p>
							</div>
							<a href="#">Reply</a>
						</div>
						</li>

						<li>
						<div className="querie_block">
							<div className="inner_bbs">
							<h3>Student Name</h3>
							<p>Querys gets posted here. </p>
							</div>
							<a href="#">Reply</a>
						</div>
						</li> */}
					</ul>
					</div>
				</div>
				</div>
			</div>
			</div>
		</div>
		</div>
	);
}

export default QueryView;
