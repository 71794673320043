import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

function AddPost() {
    const domainBackend = "https://vxp1346.uta.cloud/backend/kalyan/";
    const navigation = useNavigate();
    let user_data = JSON.parse(localStorage.getItem('user_data'));

    const [postTitle, setPostTitle] = useState('');
    const [postImage, setPostImage] = useState(null);
    const [postDesc, setPostDesc] = useState('');

    useEffect(() => {
        let access_token = localStorage.getItem('token');
        if (!access_token)
            navigation("/auth");
    }, []);

    async function savePost() {
        let payload = {
            postTitle,
            postImage: '',
            postDesc,
            created_by: user_data.id,
            action: 'create'
        }

        try {
			if (!payload.postTitle || !payload.postDesc) {
				alert('Missing Mandatory Fields');
			} else {
                const formData = new FormData();
                formData.append("file", postImage);

				const resp = await axios({
					method: "post",
					url: domainBackend + 'wdm_upload_asserts.php',
					data: formData,
					headers: { "Content-Type": "multipart/form-data" }
				});

				if (resp.data && resp.data.success) {
                    payload.postImage = resp.data.url;

                    const response = await axios({
                        method: "post",
                        url: domainBackend + 'wdm_posts.php',
                        data: payload,
                        headers: { "Content-Type": "application/json" }
                    });

                    if (response.data && response.data.success) {
                        alert('Product Created Successfully');
                        setPostTitle('');
                        setPostDesc('');
                    } else {
                        alert(response.data.message);
                    }
				} else {
					alert(resp.data.message);
				}
			}
		} catch(e) {
			console.log(e);
		}
    }

    return (
        <div className="amado_product_area profile_section">
                <div className="grd-container-fluid">

                    <div className="grd-row club_llk center">
                

                        <div className="grd-lg-7 ">
                        <div className="add_prod_section">
                            <div className="add_club_main_section">
                            <div className="clb_title">
                                <h3>Add New Posts</h3>
                            </div>
                                <div className="inner_prod_block">
                                    <div className="image-block"></div>
                                    <div className="prod_sse">
                                        <ul>
                                            <li>
                                                <h3>Post Title</h3>
                                                <input type="text" placeholder="Title" value={postTitle} onChange={event => setPostTitle(event.target.value)}/>
                                            </li>
                                            <li>
                                                <h3>Post Image</h3>
                                                <input type="file" onChange={event => setPostImage(event.target.files[0])}/>
                                            </li>
                                            <li>
                                                <h3>Post Description</h3>
                                                <textarea placeholder="Post Description" value={postDesc} onChange={event => setPostDesc(event.target.value)}></textarea>
                                            </li>
                                            <li>
                                                <div className="btn_adnn">
                                                    <button className="new_prod" onClick={savePost}>Add</button>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default AddPost