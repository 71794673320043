import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

function AddProduct() {
    const domainBackend = "https://vxp1346.uta.cloud/backend/kalyan/";
    const navigation = useNavigate();
    let user_data = JSON.parse(localStorage.getItem('user_data'));

    const [prodName, setProdName] = useState('');
    const [prodType, setProdType] = useState('');
    const [prodImage, setProdImage] = useState(null);
    const [prodPrice, setProdPrice] = useState('');

    useEffect(() => {
        let access_token = localStorage.getItem('token');
        if (!access_token)
            navigation("/auth");
    }, []);

    async function saveProduct() {
        let payload = {
            prodName,
            prodType,
            prodImage: '',
            prodPrice,
            created_by: user_data.id,
            action: 'create'
        }

        try {
			if (!payload.prodName || !payload.prodPrice) {
				alert('Missing Mandatory Fields');
			} else {
                const formData = new FormData();
                formData.append("file", prodImage);

				const resp = await axios({
					method: "post",
					url: domainBackend + 'wdm_upload_asserts.php',
					data: formData,
					headers: { "Content-Type": "multipart/form-data" }
				});

				if (resp.data && resp.data.success) {
                    payload.prodImage = resp.data.url;

                    const response = await axios({
                        method: "post",
                        url: domainBackend + 'wdm_products.php',
                        data: payload,
                        headers: { "Content-Type": "application/json" }
                    });

                    if (response.data && response.data.success) {
                        alert('Product Created Successfully');
                        setProdName('');
                        setProdType('');
                        setProdPrice('');
                    } else {
                        alert(response.data.message);
                    }
				} else {
					alert(resp.data.message);
				}
			}
		} catch(e) {
			console.log(e);
		}
    }

    return (
        <div className="amado_product_area profile_section">
                <div className="grd-container-fluid">
                    <div className="grd-row">
                        <div className="grd-12 grd-lg-12 grd-md-12">
                            <div className="page_title prod_sub_title">
                                <h3>Add Product</h3>
                            </div>
                        </div>
                    </div>

                    <div className="grd-row">
                        <div className="grd-lg-12">
                        <div className="add_prod_section sds_add_p">
                            <div className="add_club_main_section">
                            
                                <div className="inner_prod_block">
                                    <div className="image-block"></div>
                                    <div className="prod_sse">
                                        <ul>
                                            <li>
                                                <h3>Product Name</h3>
                                                <input type="text" placeholder="Title" value={prodName} onChange={event => setProdName(event.target.value)}/>
                                            </li>
                                            <li>
                                                <h3>Product Type</h3>
                                                <input type="text" placeholder="Title" value={prodType} onChange={event => setProdType(event.target.value)}/>
                                            </li>
                                            <li>
                                                <h3>Product Image</h3>
                                                <input type="file" onChange={event => setProdImage(event.target.files[0])}/>
                                            </li>
                                            <li>
                                                <h3>Product Price</h3>
                                                <input type="text" placeholder="Price" value={prodPrice} onChange={event => setProdPrice(event.target.value)}/>
                                            </li>
                                            <li>
                                                <div className="btn_adnn">
                                                    <button className="new_prod" onClick={saveProduct}>Add</button>
                                                </div>
                                                
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>

                </div>
            </div>
    )
}

export default AddProduct