import React from "react";

function About() {
  return (
    <div className="products-catagories-area">
      <div className="amado-pro-catagory">
        <div className="home_section">
          <div className="grd-container">
            <div className="grd-row about_content">
              <div className="grd-lg-5 home-text">
                <h2>About Us</h2>
                <p>
                  we believe that the best way to deliver a great experience to
                  users is to understand them genuinely what the people love the
                  most. The delivery of various products is upon us we will
                  provide a better user interface for the users to go a long way
                  to get different types of products to you. We maintain a
                  better quality of the products and services. It will take a
                  long time to get products that you like because they might be
                  out of stock in the market but we improvise our tools in a way
                  that you can search for related products more easily.
                </p>
              </div>
              <div className="grd-lg-6">
                <img src="img/about-image.jpg" className="home-img" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
