import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

function SchoolManage() {
  	const domainBackend = "https://vxp1346.uta.cloud/backend/kalyan/";
	const navigation = useNavigate();
	let user_data = JSON.parse(localStorage.getItem('user_data'));
	const [users, setUsers] = useState([]);

	useEffect(() => {
        let access_token = localStorage.getItem('token');
        if (!access_token)
            navigation("/auth");
		readUsers();
    }, []);

	async function deleteUser(id) {
		let payload = {
			action: 'modify',
			id: id
		}

		try {
			const resp = await axios({
				method: "post",
				url: domainBackend + 'wdm_users.php',
				data: payload,
				headers: { "Content-Type": "application/json" }
			});

			if (resp.data && resp.data.success) {
				alert('Deleted Account Successfully');
				readUsers();
			} else {
				alert('Failed to Delete');
			}
		} catch(e) {
			console.log(e);
		}
	}

	async function readUsers() {
		let payload = {
            action: 'getUsers',
			role: 'School Admin'
        }

        try {
			const resp = await axios({
				method: "post",
				url: domainBackend + 'wdm_users.php',
				data: payload,
				headers: { "Content-Type": "application/json" }
			});

			if (resp.data && resp.data.success) {
				let allusers = JSON.parse(resp.data.data);
				setUsers(allusers.map((user, index) => (
					<tr key={index}>
						<td>{user.id}</td>
						<td>{user.firstname}</td>
						<td>{user.lastname}</td>
						<td>{user.email}</td>
						<td onClick={()=>deleteUser(user.id)} style={{'cursor': 'pointer'}}>X</td>
					</tr>
				)));
			} else {
				alert('No Accounts Found');
				setUsers([]);
			}
		} catch(e) {
			console.log(e);
		}
	}

	return (
		<div className="amado_product_area profile_section">
		<div className="grd-container-fluid">
			<div className="grd-row">
			<div className="grd-12 grd-lg-12 grd-md-12">
				<div className="page_title prod_sub_title">
				<h3>Super Admin</h3>
				</div>
			</div>
			</div>

			<div className="grd-row">
			<div className="grd-lg-2">
				<div className="menu-tabs">
				<ul>
					<div className="menu-tabs">
					<ul>
					<li
					style={{
						marginBottom: "15px",
						borderRadius: "5px",
						padding: "10px 20px",
						listStyle: "none",
						background: "black",
					}}
					>
					<Link style={{ color: "white" }} to="/school_manage">
						Manage Schools
					</Link>
					</li>
					<li
					style={{
						marginBottom: "15px",
						borderRadius: "5px",
						padding: "10px 20px",
						listStyle: "none",
						background: "black",
					}}
					>
					<Link style={{ color: "white" }} to="/user_manage">
						Manage Users
					</Link>
					</li>
					<li
					style={{
						marginBottom: "15px",
						borderRadius: "5px",
						padding: "10px 20px",
						listStyle: "none",
						background: "black",
					}}
					>
					<Link style={{ color: "white" }} to="/query_view">
						View Queries
					</Link>
					</li>
				</ul>
					</div>
				</ul>
				</div>
			</div>
			<div className="grd-lg-9 school_reports">
				<div className="reports_title">
				<h3>Manage Schools</h3>
				</div>
				<div className="grd-lg-12">
				<div className="manage_student_table">
					<table>
					<thead>
						<tr>
						<th>Student ID</th>
						<th>First Name</th>
						<th>Last Name</th>
						<th>Email</th>
						<th>Delete</th>
						</tr>
					</thead>
					<tbody>
						{users}
					</tbody>
					</table>
				</div>
				</div>
			</div>
			</div>
		</div>
		</div>
	);
}

export default SchoolManage;
