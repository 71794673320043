import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

function Products() {
  	const domainBackend = "https://vxp1346.uta.cloud/backend/kalyan/";
	const navigation = useNavigate();
	const [allProducts, setAllProducts] = useState([]);
	let user_data = JSON.parse(localStorage.getItem('user_data'));

	useEffect(() => {
        let access_token = localStorage.getItem('token');
        if (!access_token)
            navigation("/auth");
		readProducts();
    }, []);

	async function moveToCart(product) {
		let payload = {
			action: 'create',
			prodName: product.prodName,
			prodImage: product.prodImage,
			prodPrice: product.prodPrice,
			qty: 1,
			created_by: user_data.id
		}

		const resp = await axios({
			method: "post",
			url: domainBackend + 'wdm_cart.php',
			data: payload,
			headers: { "Content-Type": "application/json" }
		});

		if (resp.data && resp.data.success) {
			alert(resp.data.message);
		} else {
			alert(resp.data.message);
		}
	}

	async function readProducts() {
		let payload = {
            action: 'retrieve'
        }

        try {
			const resp = await axios({
				method: "post",
				url: domainBackend + 'wdm_products.php',
				data: payload,
				headers: { "Content-Type": "application/json" }
			});

			if (resp.data && resp.data.success) {
				let products = JSON.parse(resp.data.data);
				setAllProducts(products.map((product, index) => (
					<div className="grd-3 grd-sm-12 grd-lg-5 grd-md-6 grd-xl-3" key={product.id}>
						<div className="single-product-wrapper">
						<div className="product-img">
							<img src={product.prodImage} alt="" />
						</div>

						<div className="product-description">
							<div className="product-meta-data">
							<div className="line"></div>
							<p className="product-price">${product.prodPrice}</p>
							<h6>{product.prodName}</h6>
							</div>
							<div className="cart">
							<a
								data-toggle="tooltip"
								data-placement="left"
								title="Add to Cart"
								style={{'cursor': 'pointer'}}
								onClick={() => moveToCart(product)}
							><img src="img/cart.png" alt="" /></a>
							</div>
						</div>
						</div>
					</div>
				)));
			} else {
				alert(resp.data.message);
			}
		} catch(e) {
			console.log(e);
		}
	}

	return (
		<div className="amado_product_area product_section">
		<div className="grd-container-fluid">
			<div className="grd-row">
			<div className="grd-12 grd-lg-12 grd-md-12">
				<div className="page_title">
				<h3>Products</h3>
				</div>
			</div>
			</div>

			<div className="grd-row">
			<div className="grd-sm-1">
				<div className="menu-tabs">
				<ul>
					<li
					style={{
						marginBottom: "15px",
						borderRadius: "5px",
						padding: "10px 20px",
						listStyle: "none",
						width:"100px",
						background: "black",
					}}
					>
					<Link style={{ color: "white" }} to="/add_product">
						Add Product
					</Link>
					</li>
				</ul>
				</div>
			</div>

			{allProducts}

			</div>
		</div>
		</div>
	);
}

export default Products;
