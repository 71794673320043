import React,{ useState } from "react";
import emailjs from '@emailjs/browser';

const sendEmail=(email)=>  {
	var templateParams = {
		notes: 'Reset password',
		toemail:email
	};
	emailjs.send('service_rtf1yhl', "template_j4i1anl", templateParams, "yoBDLs9N5xfMwcQuQ")
	
	};
  

function ForgotPass() {
  const [email, setEmail] = useState('');
  return (
    <div class="login-area">
      <div class="amado-pro-catagory">
        <div class="home_section">
          <div class="grd-container">
            <div class="grd-row contact_section login_section">
              <form id="form_id" >
                <div class="grd-lg-5">
                  <h2 >Forget Password</h2>
                  <div class="contact_form">
                    <div class="form-group">
                      <label for="user">User / Email </label>
                      <input
                        required=""
                        type="text"
                        id="user"
                        name="email"
                        class="form-control"
                        value={email} onChange={event => setEmail(event.target.value)}
                        placeholder="Username"
                      />
                    </div>

                    <button onClick={sendEmail(email)} class="submit" id="submit">
                      SUBMIT
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPass;
