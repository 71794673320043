import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

function Cart() {
    const domainBackend = "https://vxp1346.uta.cloud/backend/kalyan/";
	const navigation = useNavigate();
	const [tot_price, setTot_price] = useState(0);
	const [del_address, setDel_address] = useState([]);
	const [allItems, setAllItems] = useState([]);
	let user_data = JSON.parse(localStorage.getItem('user_data'));

    useEffect(() => {
        let access_token = localStorage.getItem('token');
        if (!access_token)
            navigation("/auth");
		readCart();
    }, []);

    async function processOrder() {
        let payload = {
            action: 'modify',
            created_by: user_data.id
        }

        const resp = await axios({
			method: "post",
			url: domainBackend + 'wdm_cart.php',
			data: payload,
			headers: { "Content-Type": "application/json" }
		});

        if (resp.data && resp.data.success) {
            saveOrder();
        }
    }

    async function saveOrder() {
        let payload = {
            action: 'create',
            order_total: parseInt(tot_price, 10),
            address: del_address,
            created_by: user_data.id
        }

        const resp = await axios({
			method: "post",
			url: domainBackend + 'wdm_order.php',
			data: payload,
			headers: { "Content-Type": "application/json" }
		});

        if (resp.data && resp.data.success) {
            alert('Order placed successfully');
            readCart();
        } else {
            alert('Failed to place Order');
        }
    }

    async function updateQty(action, id, qty) {
        qty = parseInt(qty, 10);

        let payload = {
            action: 'modify',
            id,
            qty: (action == 'minus') ? ((qty > 0) ? qty - 1 : qty) : qty + 1
        }

        const resp = await axios({
			method: "post",
			url: domainBackend + 'wdm_cart.php',
			data: payload,
			headers: { "Content-Type": "application/json" }
		});

        if (resp.data && resp.data.success) {
            readCart();
        }
    }

    async function readCart() {
        let payload = {
            action: 'retrieve',
            created_by: user_data.id
        }

        const resp = await axios({
			method: "post",
			url: domainBackend + 'wdm_cart.php',
			data: payload,
			headers: { "Content-Type": "application/json" }
		});

		if (resp.data && resp.data.success) {
            let items = JSON.parse(resp.data.data);
            setAllItems(items.map((item, index) => (
                <tr key={index}>
                    <td className="cart_product_img">
                        <a><img src={item.prodImage} alt="Product" /></a>
                    </td>
                    <td className="cart_product_desc">
                        <h5>{item.prodName}</h5>
                    </td>
                    <td className="price">
                        <span>${item.prodPrice}</span>
                    </td>
                    <td className="qty">
                        <div className="qty-btn display-flex">
                            <div className="quantity">
                                <span className="qty-minus" onClick={()=>updateQty('minus', item.id, item.qty)}><i className="fa fa-minus" aria-hidden="true"></i></span>
                                <input type="text" className="qty-text" id="qty" step="1" min="1" max="300" name="quantity" value={item.qty} />
                                <span className="qty-plus" onClick={()=>updateQty('plus', item.id, item.qty)}><i className="fa fa-plus" aria-hidden="true"></i></span>
                            </div>
                        </div>
                    </td>
                </tr>
            )));

            let totalAmt = 0;
            for(let item of items) {
                let qty_amt = item.prodPrice * item.qty;
                totalAmt += qty_amt;
            }
            setTot_price(totalAmt);
		}
    }

    return (
        <div className="cart-table-area cart_section">
                <div className="grd-container-fluid">
                    <div className="grd-row">
                        <div className="grd-12 grd-lg-12 grd-md-12">
                            <div className="page_title">
                                <h3>Cart</h3>
                            </div>
                        </div>
                    </div>

                    <div className="grd-row">
                        <div className="grd-12 grd-lg-7">
                            <div className="cart-table">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Name</th>
                                            <th>Price</th>
                                            <th>Quantity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allItems}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    
                        <div className="grd-12 grd-lg-4">
                            <div className="cart-summary">
                                <h5>Cart Total</h5>
                                <ul className="summary-table">
                                    <li><span>subtotal:</span> <span>${tot_price}</span></li>
                                    <li><span>delivery:</span> <span>Free</span></li>
                                    <li><span>total:</span> <span>${tot_price}</span></li>
                                </ul>
                                <textarea placeholder="Enter Order Delivery Address" value={del_address} onChange={event => setDel_address(event.target.value)}></textarea>
                                <div className="cart-btn mt-100">
                                    <a className="btn amado-btn w-100" onClick={processOrder}>Place Order</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default Cart