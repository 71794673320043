import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import axios from "axios";

function NavBar() {
	const domainBackend = "https://vxp1346.uta.cloud/backend/kalyan/";
	const [showBot, setShowBot] = useState('none');
  	const [displayChat, setDisplayChat] = useState('none');
	const [msgVal, setMsgVal] = useState('');
	const [renderMsg, setRenderMsg] = useState([]);
	let user_data = JSON.parse(localStorage.getItem('user_data'));

	useEffect(() => {
		let access_token = localStorage.getItem('token');
		if (access_token) {
			setDisplayChat('block');
		}
		getAllChats();
	}, []);

	const onLogout = () => {
		localStorage.removeItem("user_type");
		localStorage.removeItem('token');
		window.location.reload();
		navigate("/auth");
	};

	const links = {
		normal: [
		{
			label: "Home",
			linkTo: "/",
		},
		{
			label: "About",
			linkTo: "/about",
		},
		{
			label: "Services",
			linkTo: "/services",
		},
		{
			label: "Blog",
			outer_link: "http://kxp8798.uta.cloud/",
		},
		{
			label: "Contact Us",
			linkTo: "/contact",
		},
		{
			label: "SIGN / REGISTER",
			linkTo:"/auth"
		},
		],
		super_user: [
		{
			label: "Home",
			linkTo: "/superuser",
			reload:true
		},
		{
			label: "About",
			linkTo: "/about",
		},
		{
			label: "services",
			linkTo: "/services",
		},

		{
			label: "Blog",
			outer_link: "http://kxp8798.uta.cloud/",
		},
		{
			label: "Contact Us",
			linkTo: "/contact",
		},
		{
			label: "Sign Out",
			logout: onLogout,
		},
		],
		student:[
		{
			label: "Home",
			linkTo: "/",
		},
		{
			label: "About",
			linkTo: "/about",
		},
		{
			label: "services",
			linkTo: "/services",
		},
		{
			label: "Products",
			linkTo: "/products",
		},
		{
			label: "Clubs",
			linkTo: "/clubs",
		},
		{
			label: "Profile",
			linkTo: "/profile",
		},
		{
			label: "Cart",
			linkTo: "/cart",
		},
		{
			label: "Posts",
			linkTo: "/posts",
		},
		{
			label: "Blog",
			outer_link: "http://kxp8798.uta.cloud/",
		},
		{
			label: "Contact Us",
			linkTo: "/contact",
		},
		{
			label: "Sign Out",
			logout: onLogout,
		},
		],
		school_admin:[
		{
			label: "Home",
			linkTo: "/schoolhome",
			reload:true
		},

		{
			label: "About",
			linkTo: "/about",
		},
		{
			label: "services",
			linkTo: "/services",
		},
		{
			label: "Blog",
			outer_link: "http://kxp8798.uta.cloud/",
		},
		{
			label: "Contact Us",
			linkTo: "/contact",
		},
		{
			label: "Sign Out",
			logout: onLogout,
		},
		],
		business_owner:[
		{
			label: "Home",
			linkTo: "/businesshome",
			reload:true
		},
		{
			label: "About",
			linkTo: "/about",
		},
		{
			label: "services",
			linkTo: "/services",
		},
		{
			label: "Blog",
			outer_link: "http://kxp8798.uta.cloud/",
		},
		{
			label: "Contact Us",
			linkTo: "/contact",
		},
		{
			label: "Sign Out",
			logout: onLogout,
		},
		],
	};
	const user_type = localStorage.getItem("user_type") || "normal";
	const routes = links[user_type];
	const { pathname } = useLocation();
	const navigate = useNavigate();

	async function getAllChats() {
		let payload = {
			action: 'retrieve',
			user_id: user_data.id
		}

		const response = await axios({
			method: "post",
			url: domainBackend + 'wdm_chat_box.php',
			data: payload,
			headers: { "Content-Type": "application/json" }
		});

		if (response.data && response.data.success) {
			let chats = JSON.parse(response.data.data);
			setRenderMsg(chats.map((chat, index) => (
				(chat.chat_by == 'student') ? <li key={chat.id}>★ {chat.message}</li> : <li key={chat.id} className="right_side">★ {chat.message}</li>
			)));
		}
	}

	async function sendMessage() {
		let payload = {
			action: 'create',
			message: msgVal,
			chat_by: 'student',
			user_id: user_data.id
		}

		const response = await axios({
			method: "post",
			url: domainBackend + 'wdm_chat_box.php',
			data: payload,
			headers: { "Content-Type": "application/json" }
		});

		if (response.data && response.data.success) {
			getAllChats();
			setMsgVal('');
		}
	}

	return (
		<>
		<div className="mobile-nav">
			<div className="amado-navbar-brand">
			<a href="/">
				<img src="img/logo.png" alt="" />
			</a>
			</div>
			<div className="amado-navbar-toggler">
			<span></span>
			<span></span>
			<span></span>
			</div>
		</div>
		<header className="header-area">
			<div className="logo">
			<a href="index.html">
				<img src="img/logo.png" alt="" />
			</a>
			</div>
			<nav className="amado-nav">
			<ul>
				{routes?.map((link, key) => (
				<li
					key={key}
					className={link.linkTo === pathname ? "active" : ""}
				>
					{link?.outer_link ? (
					<a href={link?.outer_link} target="__blank">
						{link.label}
					</a>
					) : link?.logout ? (
					<a href="#" onClick={link.logout}>
						{link.label}
					</a>
					) : (
					<Link reloadDocument={link?.reload} to={link.linkTo}>{link.label}</Link>
					)}
				</li>
				))}
			</ul>
			</nav>
		</header>

		<div className="chatbox_section" style={{'display': displayChat}}>
			<div className="chat" onClick={()=>setShowBot('block')}>
				<img src="https://vxp1346.uta.cloud/backend/kalyan/asserts/chat_icon.png" />
			</div>

			<div className='chatbox' style={{'display': showBot}}>
			<h2>Messages <span onClick={()=>setShowBot('none')}>X</span></h2>
			<ul className="chat_messages">
				{renderMsg}
			</ul>
			<div className="msg_box">
				<input type="text" placeholder="Enter your Message" className="chat_input" value={msgVal} onChange={event => setMsgVal(event.target.value)}/>
				<button className="send" onClick={sendMessage}>Send</button>
			</div>
			</div>
		</div>
		</>
	);
}

export default NavBar;
