import { Routes, Route } from "react-router-dom";
import About from "./components/About";
import AddPost from "./components/AddPost";
import AddProduct from "./components/AddProduct";
import BaseHome from "./components/BaseHome";
import Contact from "./components/Contact";
import NavBar from "./components/NavBar";
import RegisterLogin from "./components/RegisterLogin";
import Cart from "./components/student/Cart";
import HomeStudent from "./components/student/Home";
import Posts from "./components/student/Posts";
import Products from "./components/student/Products";
import Profile from "./components/student/Profile";
import Services from "./components/Services";
import AddClub from "./components/AddClub";
import Clubs from "./components/student/Clubs";
import HomeSuperUser from "./components/super/Home";
import QueryView from "./components/super/QueryView";
import SchoolManage from "./components/super/SchoolManage";
import UserManage from "./components/super/UserManage";
import HomeSchool from "./components/school/Home";
import OwnerManage from "./components/school/OwnerManage";
import StudentManage from "./components/school/StudentManage";
import ModeratePosts from "./components/school/ModeratePosts";
import HomeBusiness from "./components/business/Home";
import AddPromotion from "./components/business/AddPromotion";
import MyProducts from "./components/business/MyProducts";
import ForgotPass from "./components/ForgotPass";

function App() {
  return (
    <div class="main-content-wrapper display-flex">
      <NavBar />
      <Routes>
        <Route path="/" caseSensitive element={<BaseHome />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/services" element={<Services />} />
        <Route path="/auth" element={<RegisterLogin />} />
        <Route path="/superuser" element={<HomeSuperUser />} />
        <Route path="/user_manage" element={<UserManage />} />
        <Route path="/school_manage" element={<SchoolManage />} />
        <Route path="/query_view" element={<QueryView />} />
        <Route path="/clubs" element={<Clubs />} />
        <Route path="/add_club" element={<AddClub />} />
        <Route path="/studenthome" element={<HomeStudent />} />
        <Route path="/posts" element={<Posts />} />
        <Route path="/products" element={<Products />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/add_product" element={<AddProduct />} />
        <Route path="/add_post" element={<AddPost />} />
        <Route path="/schoolhome" element={<HomeSchool />} />
        <Route path="/managebusiness" element={<OwnerManage />} />
        <Route path="/managestudent" element={<StudentManage />} />
        <Route path="/moderateposts" element={<ModeratePosts />} />
        <Route path="/businesshome" element={<HomeBusiness />} />
        <Route path="/myaddproduct" element={<MyProducts />} />
        <Route path="/addpromotion" element={<AddPromotion />} />
        <Route path="/forgotpassword" element={<ForgotPass />} />

      </Routes>
    </div>
  );
}

export default App;
