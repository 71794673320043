import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

function Posts() {
  	const domainBackend = "https://vxp1346.uta.cloud/backend/kalyan/";
	const navigation = useNavigate();
	const [allPosts, setAllPosts] = useState([]);
	let user_data = JSON.parse(localStorage.getItem('user_data'));

	useEffect(() => {
		let access_token = localStorage.getItem('token');
		if (!access_token)
			navigation("/auth");
		readPosts();
	}, []);

	async function readPosts() {
		let payload = {
            action: 'retrieve'
        }

        try {
			const resp = await axios({
				method: "post",
				url: domainBackend + 'wdm_posts.php',
				data: payload,
				headers: { "Content-Type": "application/json" }
			});

			if (resp.data && resp.data.success) {
				let posts = JSON.parse(resp.data.data);
				setAllPosts(posts.map((post, index) => (
					<div className="grd-3 grd-lg-3 grd-sm-5 grd-md-6 grd-xl-3" key={post.id}>
						<div className="single-product-wrapper">
						<div className="product-img">
							<img src={post.postImage} alt="" />
						</div>

						<div className="product-description">
							<div className="product-meta-data">
							<span className="posted_date">Posted on : {post.created_date_time}</span>
							<h3>{post.postTitle}</h3>
							<p>{post.postDesc}</p>
							</div>
						</div>
						</div>
					</div>
				)));
			} else {
				alert(resp.data.message);
			}
		} catch(e) {
			console.log(e);
		}
	}

	return (
		<div className="amado_product_area posts_section">
		<div className="grd-container-fluid">
			<div className="grd-row">
			<div className="grd-12 grd-lg-12 grd-md-12">
				<div className="page_title">
				<h3>Posts</h3>
				</div>
			</div>
			</div>

			<div className="grd-row">
			<div className="grd-sm-1">
				<div className="menu-tabs">
				<ul>
					<li
						style={{
							marginBottom: "15px",
							borderRadius: "5px",
							padding: "10px 20px",
							listStyle: "none",
							width:"100px",
							background: "black"
						}}
					>
					<Link style={{ color: "white" }} to="/add_post">
						Add Posts
					</Link>
					</li>
				</ul>
				</div>
			</div>

			{allPosts}
			
			</div>
		</div>
		</div>
	);
}

export default Posts;
