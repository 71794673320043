import React from 'react'
import { useState } from 'react';
import axios from "axios";

function Contact () {
  	const domainBackend = "https://vxp1346.uta.cloud/backend/kalyan/";

  	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');

	function validateEmail(email) {
		var re = /\S+@\S+\.\S+/;
		return re.test(email);
	}

	async function submitQuery() {
		const payload = {
			name,
			email,
			message
		}

		try {
			if (!payload.name || !payload.email || !payload.message) {
				alert('Missing Mandatory Fields');
			} else if(!validateEmail(payload.email)) {
				alert('Email is not Valid');
			} else {
				const resp = await axios({
					method: "post",
					url: domainBackend + 'wdm_enquiry.php',
					data: payload,
					headers: { "Content-Type": "application/json" },
				});
	
				if (resp.data && resp.data.success) {
					alert(resp.data.message);
					setName('');
					setEmail('');
					setMessage('');
				} else {
					alert(resp.data.message);
				}
			}
		} catch(e) {
			console.log(e);
		}
	}

	return (
		<div className='products-catagories-area'>
			<div className='amado-pro-catagory'>
				<div className='home_section'>
				<div className='grd-container'>
					<div className='grd-row contact_section'>
					<div className='grd-lg-12'>
						<h2>Do you want to get in touch?</h2>
						<div className='contact_form'>
							<div className='form-group'>
								<label for='contact_form__name'>Name</label>
								<input
								type='text'
								id='contact_form__name'
								name='contact[name]'
								className='form-control'
								placeholder='Name'
								value={name} onChange={event => setName(event.target.value)}
								/>
							</div>

							<div className='form-group'>
								<label for='contact_form__email'>Email</label>
								<input
								type='email'
								id='contact_form__email'
								name='contact[email]'
								className='form-control'
								placeholder='Email'
								value={email} onChange={event => setEmail(event.target.value)}
								/>
							</div>

							<div className='form-group'>
								<label for='contact_form__message'>Message</label>
								<textarea
								id='contact_form__message'
								name='contact[body]'
								className='form-control'
								placeholder='Message'
								value={message} onChange={event => setMessage(event.target.value)}
								></textarea>
							</div>
								<button className='submit' onClick={submitQuery}>SUBMIT</button>
							</div>
						</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Contact
