import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

function AddClub() {
  const domainBackend = "https://vxp1346.uta.cloud/backend/kalyan/";
    const navigation = useNavigate();
    let user_data = JSON.parse(localStorage.getItem('user_data'));

    const [clubName, setClubName] = useState('');
    const [clubImage, setClubImage] = useState(null);

    useEffect(() => {
        let access_token = localStorage.getItem('token');
        if (!access_token)
            navigation("/auth");
    }, []);

	async function saveClub() {
		let payload = {
			clubName,
			clubImage: '',
			created_by: user_data.id,
			action: 'create'
		}

		try {
			if (!payload.clubName) {
				alert('Missing Mandatory Fields');
			} else {
                const formData = new FormData();
                formData.append("file", clubImage);

				const resp = await axios({
					method: "post",
					url: domainBackend + 'wdm_upload_asserts.php',
					data: formData,
					headers: { "Content-Type": "multipart/form-data" }
				});

				if (resp.data && resp.data.success) {
                    payload.clubImage = resp.data.url;

                    const response = await axios({
                        method: "post",
                        url: domainBackend + 'wdm_clubs.php',
                        data: payload,
                        headers: { "Content-Type": "application/json" }
                    });

                    if (response.data && response.data.success) {
                        alert('Club Created Successfully');
                        setClubName('');
                    } else {
                        alert(response.data.message);
                    }
				} else {
					alert(resp.data.message);
				}
			}
		} catch(e) {
			console.log(e);
		}
	}

	return (
		<div className="amado_product_area profile_section">
		<div className="grd-container-fluid">
			<div className="grd-row">
			<div className="grd-12 grd-lg-12 grd-md-12">
				<div className="page_title prod_sub_title">
				<h3>Add Club</h3>
				</div>
			</div>
			</div>

			<div className="grd-row">
			<div className="grd-lg-12">
				<div className="add_prod_section sds_add_p">
				<div className="add_club_main_section">
					<div className="inner_prod_block">
					<div className="image-block"></div>
					<div className="prod_sse">
						<ul>
						<li>
							<h3>Club Name</h3>
							<input type="text" placeholder="Title"  value={clubName} onChange={event => setClubName(event.target.value)}/>
						</li>
						<li>
							<h3>Club Logo</h3>
							<input type="file" onChange={event => setClubImage(event.target.files[0])}/>
						</li>
						<li>
							<div className="btn_adnn">
							<button className="new_prod" onClick={saveClub}>Add</button>
							</div>
						</li>
						</ul>
					</div>
					</div>
				</div>
				</div>
			</div>
			</div>
		</div>
		</div>
	);
}

export default AddClub;
