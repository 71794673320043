import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

function Clubs() {
	const domainBackend = "https://vxp1346.uta.cloud/backend/kalyan/";
	const navigation = useNavigate();
	const [allClubs, setAllClubs] = useState([]);
	let user_data = JSON.parse(localStorage.getItem('user_data'));

	useEffect(() => {
        let access_token = localStorage.getItem('token');
        if (!access_token)
            navigation("/auth");
		readClubs();
    }, []);

	async function getClub(club_action, club_id, club_name) {
		let payload = {
			action: 'create',
			club_id,
			club_action,
			club_name,
			created_by: user_data.id
		}

		if (club_action == 'leave') {
			payload.action = 'delete';
		}

		try {
			const resp = await axios({
				method: "post",
				url: domainBackend + 'wdm_club_system.php',
				data: payload,
				headers: { "Content-Type": "application/json" }
			});

			if (resp.data && resp.data.success) {
				alert(club_action + ' the Club successfully');
			} else {
				alert(resp.data.message);
			}
		} catch(e) {
			console.log(e);
		}
	}

	async function readClubs() {
		let payload = {
            action: 'retrieve'
        }

        try {
			const resp = await axios({
				method: "post",
				url: domainBackend + 'wdm_clubs.php',
				data: payload,
				headers: { "Content-Type": "application/json" }
			});

			if (resp.data && resp.data.success) {
				let clubs = JSON.parse(resp.data.data);
				setAllClubs(clubs.map((club, index) => (
					<div className="grd-3 grd-sm-5 grd-md-6 grd-xl-3" key={club.id}>
						<div className="single-product-wrapper">
						<div className="product-img">
							<img src={club.clubImage} alt="" />
						</div>

						<div className="product-description">
							<div className="product-meta-data">
							<ul>
								<li>
								<a onClick={()=>getClub('join', club.id, club.clubName)} 
									style={{'cursor': 'pointer'}}
									className="add_club">
									Add Club
								</a>
								</li>
								<li>
								<a onClick={()=>getClub('leave', club.id, club.clubName)} 
									style={{'cursor': 'pointer'}}
									className="leave_club">
									Leave Club
								</a>
								</li>
							</ul>
							</div>
						</div>
						</div>
					</div>
				)));
			} else {
				alert(resp.data.message);
			}
		} catch(e) {
			console.log(e);
		}
	}

	return (
		<div className="amado_product_area club_section">
		<div className="grd-container-fluid">
			<div className="grd-row">
			<div className="grd-12 grd-lg-12 grd-md-12">
				<div className="page_title">
				<h3>Clubs</h3>
				</div>
			</div>
			</div>

			<div className="grd-row">
			<div className="menu-tabs" style={{'width': '100%'}}>
				<ul>
					<li
					style={{
						marginBottom: "15px",
						borderRadius: "5px",
						padding: "10px 20px",
						listStyle: "none",
						width:"100px",
						background: "black",
					}}
					>
					<Link style={{ color: "white" }} to="/add_club">
						Add Club
					</Link>
					</li>
				</ul>
				</div>
				{allClubs}
			</div>
		</div>
		</div>
	);
}

export default Clubs;
