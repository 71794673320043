import React from 'react'

function Services () {
  return (
    <div className='products-catagories-area'>
      <div className='amado-pro-catagory'>
        <div className='home_section'>
          <div className='grd-container'>
            <div className='grd-row about_content'>
              <div className='grd-lg-5 home-text'>
                <h2>About Our Services</h2>
                <p>
                  We provide a broad number of services that include
                  Advertisements, Posts, Clubs, and Seller by product.
                  Advertisements are powerful tools that help businesses to
                  improve and enhance their market value and for small
                  businesses, it helps a lot in starting phase of their work.
                  Advertisements can directly increase sales by driving more
                  consumers to websites and specific product pages in this
                  situation we provide the best quality advertisements. In the
                  advertisements we depict brand new products For posts, you can
                  read or create posts, Clubs are different types where you can
                  join or create new clubs. The Ecommerce Club is designed to
                  mentor members and enable collaboration between all parties.
                  Finally, the Seller by-products where we publish a vast number
                  of products from different sellers. Bestsellers can often help
                  offset the lower sales of other products. As it brings a
                  consistent revenue stream, it can make up for the shortfalls
                  created by other items.In e-commerce, your prices have to be
                  better because the consumer has to take a leap of faith in
                  your product.
                </p>
              </div>
              <div className='grd-lg-6'>
                <img src='img/about-image.jpg' className='home-img' alt='' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Services
