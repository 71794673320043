import React from "react";

function BaseHome() {
  return (
    <div className="products-catagories-area">
      <div className="amado-pro-catagory">
        <div className="home_section">
          <div className="grd-container">
            <div className="grd-row home_content">
              <div className="grd-lg-6">
                <img src="img/home-image.jpg" className="home-img" alt="" />
              </div>
              <div className="grd-lg-5 home-text">
                <h2>What we do</h2>
                <p>
                  Less time can be spent on shopping by students. They can
                  quickly go over a variety of items at once and purchase what
                  they desire. Students can find things that are sold in
                  colleges online, which is where eCommerce comes to the aid of
                  many customers. They use the internet to conduct a short
                  search, receive a prompt response, and then purchase the item.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BaseHome;
