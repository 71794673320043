import React from "react";
import { Link } from "react-router-dom";

function HomeSuperUser() {
  return (
    <div className="amado_product_area profile_section">
      <div className="grd-container-fluid">
        <div className="grd-row">
          <div className="grd-12 grd-lg-12 grd-md-12">
            <div className="page_title prod_sub_title">
              <h3>Super Admin</h3>
            </div>
          </div>
        </div>

        <div className="grd-row">
          <div className="grd-lg-2">
            <div className="menu-tabs">
              <ul>
                <li
                  style={{
                    marginBottom: "15px",
                    borderRadius: "5px",
                    padding: "10px 20px",
                    listStyle: "none",
                    background: "black",
                  }}
                >
                  <Link style={{ color: "white" }} to="/school_manage">
                    Manage Schools
                  </Link>
                </li>
                <li
                  style={{
                    marginBottom: "15px",
                    borderRadius: "5px",
                    padding: "10px 20px",
                    listStyle: "none",
                    background: "black",
                  }}
                >
                  <Link style={{ color: "white" }} to="/user_manage">
                    Manage Users
                  </Link>
                </li>
                <li
                  style={{
                    marginBottom: "15px",
                    borderRadius: "5px",
                    padding: "10px 20px",
                    listStyle: "none",
                    background: "black",
                  }}
                >
                  <Link style={{ color: "white" }} to="/query_view">
                    View Queries
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="grd-lg-9 school_reports">
            <div className="reports_title">
              <h3>Reports</h3>
            </div>
            <div className="grd-lg-12">
              <div className="report_graph_section">
                <div
                  id="piechart"
                  style={{ width: "100%", height: "350px" }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeSuperUser;
