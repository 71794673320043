import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import emailjs from '@emailjs/browser';

function sendEmail(fname,lname,toemail)  {
	// preventDefault(); 
	console.log("invoked")
	var templateParams = {
		name: fname+" "+lname,
		notes: 'User Created Successfully',
		toemail:toemail
	};
	emailjs.send('service_rtf1yhl', "template_x1rf9ts", templateParams, "yoBDLs9N5xfMwcQuQ")
	
	};


function RegisterLogin() {
	const domainBackend = "https://vxp1346.uta.cloud/backend/kalyan/";

	useEffect(() => {
        let access_token = localStorage.getItem('token');
        if (access_token)
            navigation("/profile");
    }, []);

	const navigation = useNavigate();
	const [firstname, setFirstname] = useState('');
	const [lastname, setLastname] = useState('');
	const [email, setEmail] = useState('');
	// const [role, setRole] = useState('');
	const [password, setPassword] = useState('');
	const [cpassword, setCpassword] = useState('');
	
	const [username, setUsername] = useState('');
	const [lgPassword, setLgPassword] = useState('');

	async function login() {
		let payload = {
			email: username,
			password: lgPassword,
			action: 'retrieve'
		}

		try {
			if (!payload.email || !payload.password) {
				alert('Missing Mandatory Fields');
			} else {
				const resp = await axios({
					method: "post",
					url: domainBackend + 'wdm_users.php',
					data: payload,
					headers: { "Content-Type": "application/json" },
				});

				if (resp.data && resp.data.success) {
					setUsername('');
					setLgPassword('');
					let access_token = resp.data.access_token;
					localStorage.setItem('token', access_token);
					let user_data = resp.data.data;
					localStorage.setItem('user_data', user_data);
					let user_id = JSON.parse(user_data).id;
					localStorage.setItem('userId', user_id);
					let display_name = JSON.parse(user_data).firstname;
					localStorage.setItem('name', display_name);
					let user_role = JSON.parse(user_data).role;
					localStorage.setItem('role', user_role);
					switch (user_role) {
						case 'Student':
							localStorage.setItem('user_type', 'student');
							break;
						case 'Business Owner':
							localStorage.setItem('user_type', 'business_owner');
							break;
						case 'School Admin':
							localStorage.setItem('user_type', 'school_admin');
							break;
						case 'Super Admin':
							localStorage.setItem('user_type', 'super_user');
							break;
						default:
							localStorage.setItem('user_type', 'student');
					}
					window.location.reload();
					navigation("/profile");
				} else {
					alert(resp.data.message);
				}
			}
		} catch(e) {
			console.log(e);
		}
	}
	
  	async function registration() {
		const payload = {
			firstname,
			lastname,
			email,
			role: 'Student',
			password,
			cpassword,
			action: 'create'
		}

		try {
			if (!payload.firstname || !payload.lastname || !payload.email || !payload.password) {
				alert('Missing Mandatory Fields');
			} else if(!validateEmail(payload.email)) {
				alert('Email is not Valid');
			} else if(payload.password.length < 6) {
				alert('Password Length to be Increased');
			} else {
				const resp = await axios({
					method: "post",
					url: domainBackend + 'wdm_users.php',
					data: payload,
					headers: { "Content-Type": "application/json" },
				});

				if (resp.data && resp.data.success) {
					// alert(resp.data.message);
					sendEmail(payload.firstname,payload.lastname,payload.email);
					// alert("Alter triggered")
					setFirstname('');
					setLastname('');
					setEmail('');
					// setRole('');
					setPassword('');
					setCpassword('');
				} else {
					alert(resp.data.message);
				}
			}
		} catch(e) {
			console.log(e);
		}
  	}

  	function validateEmail(email) {
		var re = /\S+@\S+\.\S+/;
		return re.test(email);
	}

	return (
		<div className="main-content-wrapper display-flex" style={{'width': '100%'}}>
		<div className="login-area">
			<div className="amado-pro-catagory">
			<div className="home_section">
				<div className="page_banner">
				<div className="page_title login_title">
					<h3>Login / Register</h3>
				</div>
				</div>

				<div className="grd-container">
				<div className="grd-row contact_section login_section">
					<div className="grd-lg-5">
						<h2>Sign In</h2>
						<div className="contact_form">
						<div className="form-group">
							<label for="user">User / Email </label>
							<input
								type="text"
								id="user"
								name="username"
								className="form-control"
								placeholder="Username"
								value={username} onChange={event => setUsername(event.target.value)}
							/>
						</div>

						<div className="form-group">
							<label for="password">Password</label>
							<input
								type="password"
								id="lg_password"
								name="pass"
								className="form-control"
								placeholder="Password"
								value={lgPassword} onChange={event => setLgPassword(event.target.value)}
							/>
							<p style={{ cursor: "pointer" }}>
							<Link
								to="/forgotpassword"
								style={{ color: "skyblue", font: "20px" }}
							>Forget Password..?
							</Link>
							</p>
						</div>

						<button className="submit" id="submit" onClick={login}>
							SIGN IN
						</button>
						</div>
					</div>

					<div className="grd-lg-5">
						<h2>Sign Up</h2>
						<div className="contact_form">
						<div className="form-group">
							<label for="firstname">First Name</label>
							<input
							type="text"
							id="firstname"
							name="firstname"
							className="form-control"
							placeholder="First Name"
							value={firstname} onChange={event => setFirstname(event.target.value)}
							/>
						</div>

						<div className="form-group">
							<label for="lastname">Last Name</label>
							<input
							type="text"
							id="lastname"
							name="lastname"
							className="form-control"
							placeholder="Last Name"
							value={lastname} onChange={event => setLastname(event.target.value)}
							/>
						</div>

						<div className="form-group">
							<label for="email">Email Address</label>
							<input
							type="email"
							id="email"
							name="email"
							className="form-control"
							placeholder="Email"
							value={email} onChange={event => setEmail(event.target.value)}
							/>
						</div>

						{/* <div className="form-group">
							<label for="email">User Role:</label>
							<label for="email">(Student / Business Owner / School Admin / Super Admin)</label>
							<input
							type="text"
							id="role"
							name="role"
							className="form-control"
							placeholder="Role"
							value={role} onChange={event => setRole(event.target.value)}
							/>
						</div> */}

						<div className="form-group">
							<label for="password">Password</label>
							<input
							type="password"
							id="password"
							name="password"
							className="form-control"
							placeholder="Password"
							value={password} onChange={event => setPassword(event.target.value)}
							/>
						</div>

						<div className="form-group">
							<label for="cpassword">Confirm Password</label>
							<input
							type="password"
							id="cpassword"
							name="cpassword"
							className="form-control"
							placeholder="Confirm Password"
							value={cpassword} onChange={event => setCpassword(event.target.value)}
							/>
						</div>

						<button className="submit" onClick={registration}>REGISTER</button>
						</div>
					</div>
				</div>
				</div>
			</div>
			</div>
		</div>
		</div>
	);
}

export default RegisterLogin;
