import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

function MyProducts() {
  	const domainBackend = "https://vxp1346.uta.cloud/backend/kalyan/";
	const navigation = useNavigate();
	const [allProducts, setAllProducts] = useState([]);
	let user_data = JSON.parse(localStorage.getItem('user_data'));

	useEffect(() => {
        let access_token = localStorage.getItem('token');
        if (!access_token)
            navigation("/auth");
		readProducts();
    }, []);

	async function deleteProduct(product) {
		let payload = {
			action: 'modify',
			id: product.id
		}

		try {
			const resp = await axios({
				method: "post",
				url: domainBackend + 'wdm_products.php',
				data: payload,
				headers: { "Content-Type": "application/json" }
			});

			if (resp.data && resp.data.success) {
				alert('Product deleted successfully');
				readProducts();
			} else {
				alert(resp.data.message);
			}
		} catch(e) {
			console.log(e);
		}
	}

	async function readProducts() {
		let payload = {
            action: 'retrieve'
        }

        try {
			const resp = await axios({
				method: "post",
				url: domainBackend + 'wdm_products.php',
				data: payload,
				headers: { "Content-Type": "application/json" }
			});

			if (resp.data && resp.data.success) {
				let products = JSON.parse(resp.data.data);
				setAllProducts(products.map((product, index) => (
					<div className="grd-3 grd-sm-12 grd-lg-5 grd-md-6 grd-xl-3" key={product.id}>
						<div className="single-product-wrapper">
						<div className="product-img">
							<img src={product.prodImage} alt="" />
						</div>

						<div className="product-description">
							<div className="product-meta-data">
							<div className="line"></div>
							<p className="product-price">${product.prodPrice}</p>
							<h6>{product.prodName}</h6>
							</div>
							<div className="edit_prod">
								<a style={{'cursor': 'pointer'}} onClick={() => deleteProduct(product)} className="delete">
								Delete
								</a>
							</div>
						</div>
						</div>
					</div>
				)));
			} else {
				alert(resp.data.message);
			}
		} catch(e) {
			console.log(e);
		}
	}

	return (
		<div className="amado_product_area profile_section">
		<div className="grd-container-fluid">
			<div className="grd-row">
			<div className="grd-12 grd-lg-12 grd-md-12">
				<div className="page_title prod_sub_title">
				<h3>All Product</h3>
				</div>
			</div>
			</div>

			<div className="grd-row admin_product">
			<div className="grd-lg-2">
				<div className="menu-tabs">
				<ul>
					<li
					style={{
						marginBottom: "15px",
						borderRadius: "5px",
						padding: "10px 20px",
						listStyle: "none",
						background: "black",
					}}
					>
					<Link style={{ color: "white" }} to="/myaddproduct">
						All Products
					</Link>
					</li>
					<li
					style={{
						marginBottom: "15px",
						borderRadius: "5px",
						padding: "10px 20px",
						listStyle: "none",
						background: "black",
					}}
					>
					<Link style={{ color: "white" }} to="/addpromotion">
						Add Promotion
					</Link>
					</li>
					<li
					style={{
						marginBottom: "15px",
						borderRadius: "5px",
						padding: "10px 20px",
						listStyle: "none",
						background: "black",
					}}
					>
					<Link style={{ color: "white" }} to="/businesshome">
						Chat History
					</Link>
					</li>
				</ul>
				</div>
			</div>
			<div className="grd-lg-9">
				{allProducts}
				{/* <div className="grd-3 grd-sm-12 grd-lg-5 grd-md-6 grd-xl-3">
				<div className="single-product-wrapper">
					<div className="product-img">
					<img src="img/product-img/product1.jpg" alt="" />
					</div>

					<div className="product-description">
					<div className="product-meta-data">
						<div className="line"></div>
						<p className="product-price">$180</p>
						<h6>Product 1</h6>
					</div>
					<div className="edit_prod">
						<a href="#" className="delete">
						Delete
						</a>
						<a href="./add_my_product.html" className="edit">
						Edit
						</a>
					</div>
					</div>
				</div>
				</div>
				<div className="grd-3 grd-sm-12 grd-lg-5 grd-md-6 grd-xl-3">
				<div className="single-product-wrapper">
					<div className="product-img">
					<img src="img/product-img/product1.jpg" alt="" />
					</div>

					<div className="product-description">
					<div className="product-meta-data">
						<div className="line"></div>
						<p className="product-price">$180</p>
						<h6>Product 1</h6>
					</div>
					<div className="edit_prod">
						<a href="#" className="delete">
						Delete
						</a>
						<a href="./add_my_product.html" className="edit">
						Edit
						</a>
					</div>
					</div>
				</div>
				</div> */}
			</div>
			</div>
		</div>
		</div>
	);
}

export default MyProducts;
