import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

function HomeBusiness() {
  	const domainBackend = "https://vxp1346.uta.cloud/backend/kalyan/";
	const navigation = useNavigate();
	let user_data = JSON.parse(localStorage.getItem('user_data'));
	const [users, setUsers] = useState([]);
	const [msgVal, setMsgVal] = useState('');
	const [stid, setStid] = useState(0);
	const [stName, setStName] = useState('');
	const [renderMsg, setRenderMsg] = useState([]);

	useEffect(() => {
        let access_token = localStorage.getItem('token');
        if (!access_token)
            navigation("/auth");
		readUsers();
    }, []);

	async function startChat(user_id, studentName) {
		setStid(user_id);
		setStName(studentName);
		getAllChat(user_id);
	}

	async function getAllChat(user_id) {
		let payload = {
			action: 'retrieve',
			user_id: user_id
		}

		const response = await axios({
			method: "post",
			url: domainBackend + 'wdm_chat_box.php',
			data: payload,
			headers: { "Content-Type": "application/json" }
		});

		if (response.data && response.data.success) {
			let chats = JSON.parse(response.data.data);
			setRenderMsg(chats.map((chat, index) => (
				(chat.chat_by == 'student') ? <li key={chat.id}>★ {chat.message}</li> : <li key={chat.id} className="right_side">★ {chat.message}</li>
			)));
		}
	}

	async function readUsers() {
		let payload = {
            action: 'getUsers',
			role: 'Student'
        }

        try {
			const resp = await axios({
				method: "post",
				url: domainBackend + 'wdm_users.php',
				data: payload,
				headers: { "Content-Type": "application/json" }
			});

			if (resp.data && resp.data.success) {
				let allusers = JSON.parse(resp.data.data);
				setUsers(allusers.map((user, index) => (
					<li key={index} onClick={()=>startChat(user.id, user.firstname + ' ' + user.lastname)}>{user.firstname + ' ' + user.lastname}</li>
				)));
			} else {
				alert('No Accounts Found');
				setUsers([]);
			}
		} catch(e) {
			console.log(e);
		}
	}

	async function sendMessage() {
		let payload = {
			action: 'create',
			message: msgVal,
			chat_by: 'business_owner',
			user_id: stid
		}

		const response = await axios({
			method: "post",
			url: domainBackend + 'wdm_chat_box.php',
			data: payload,
			headers: { "Content-Type": "application/json" }
		});

		if (response.data && response.data.success) {
			setMsgVal('');
			getAllChat(stid);
		}
	}

	return (
		<div className="amado_product_area profile_section">
		<div className="grd-container-fluid">
			<div className="grd-row">
			<div className="grd-12 grd-lg-12 grd-md-12">
				<div className="page_title prod_sub_title">
				<h3>Business Owner</h3>
				</div>
			</div>
			</div>

			<div className="grd-row">
			<div className="grd-lg-2">
				<div className="menu-tabs">
				<ul>
					<li
					style={{
						marginBottom: "15px",
						borderRadius: "5px",
						padding: "10px 20px",
						listStyle: "none",
						background: "black",
					}}
					>
					<Link style={{ color: "white" }} to="/myaddproduct">
						All Products
					</Link>
					</li>
					<li
					style={{
						marginBottom: "15px",
						borderRadius: "5px",
						padding: "10px 20px",
						listStyle: "none",
						background: "black",
					}}
					>
					<Link style={{ color: "white" }} to="/addpromotion">
						Add Promotion
					</Link>
					</li>
					<li
					style={{
						marginBottom: "15px",
						borderRadius: "5px",
						padding: "10px 20px",
						listStyle: "none",
						background: "black",
					}}
					>
					<Link style={{ color: "white" }} to="/businesshome">
						Chat History
					</Link>
					</li>
				</ul>
				</div>
			</div>
			<div className="grd-lg-5 student_chat_section">
				<div className="grd-lg-12">
				<div className="chat_window_section">
					<div className="active_student">
					<h3>{stName}</h3>
					</div>
					<div className="chht_window">
						<ul className="chat_messages">
							{renderMsg}
						</ul>
					</div>
					<div className="lks_block">
					<input type="text" placeholder="Write Something..." style={{'width': '65%'}}  value={msgVal} onChange={event => setMsgVal(event.target.value)}/>
					<button onClick={sendMessage}>Send</button>
					</div>
				</div>
				</div>
			</div>
			<div className="grd-lg-3">
				<div className="student_list">
				<ul>
					{users}
				</ul>
				</div>
			</div>
			</div>
		</div>
		</div>
	);
}

export default HomeBusiness;
